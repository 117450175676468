import { axiosInstance } from "../../axios";

const GETSearchBallot = `/api/Ballot/SearchBallot`;
const GETSearchOpenBallot = `/api/Ballot/UserOpenBallot`;
const GETSearchCurrentBallot = `/api/Ballot/UserCurrentBallot`;
const GETsearchByKey = `/api/Ballot/GetById/`;
const GETsearchByKeyBallotRequest = `/api/Home/GetByBallotRequest/`;
const POSTSaveBallot = `/api/Ballot/CreateBallot`;
const DELETERemoveBallot = `/api/Ballot/Delete`;
const POSTToggleBallotDay = `/api/Ballot/ToggleBallotDay`;
const POSTOptInBallot = `/api/Ballot/OptInBallot`;
const POSTOptOutBallot = `/api/Ballot/OptOutBallot`;
const GETSearchBallotOptIns = `/api/Ballot/SearchBallotOptIns`;
const GETSearchAllBallotOptIns = `/api/Ballot/SearchBallotOptIns`;
const POSTOptInOrOptOut = `/api/Home/OptInBallot`;
const POSTBallotRequestExportToExcel = `/api/BallotRequest/ExportToExcel`;
const GETSearchTodaysBallot = `/api/Ballot/SearchTodaysBallot`;
const GETSearchRequestedBallot = `/api/Ballot/SearchRequestedBallot/`;


const SearchBallot = async function() {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETSearchBallot);
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const SearchBallotByRequestKey = async function(id) {
    try {
        const response = await axiosInstance.bhPublicAPIInstance.get(GETsearchByKeyBallotRequest + id);
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const OptInOrOptOut = async function(payload) {
    try {
        const response = await axiosInstance.bhPublicAPIInstance.post(POSTOptInOrOptOut, payload);
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};


const searchBallotOptIns = async function(ballotId, status, search) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(`${GETSearchBallotOptIns}/${ballotId}/status/${status}/search/${search}`);
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const searchAllBallotOptIns = async function(ballotId) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(`${GETSearchAllBallotOptIns}/${ballotId}`);
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const SearchOpenBallot = async function() {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETSearchOpenBallot);
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};

const searchCurrentBallot = async function() {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETSearchCurrentBallot);
        let data = parseList(response);
        return data;
    } catch (error) {
        return [];
    }
};


const SearchBallotByKey = async function(id) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETsearchByKey + id);
        return response.data;
    } catch (error) {
        return [];
    }
};

const SaveBallot = async function(ballot) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTSaveBallot, ballot);
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const ballotRequestExportToExcel = async function(ballot) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTBallotRequestExportToExcel, ballot);
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const removeBallot = async function(request) {
    try {
        const response = await axiosInstance.bhAPIInstance.delete(DELETERemoveBallot, request);
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const toggleBallotDay = async function(value) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(`${POSTToggleBallotDay}/${value}`);
        return response.data;
    } catch (error) {
        return parseError(error);
    }
}

const optInBallot = async function(ballot) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTOptInBallot, ballot);
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const optOutBallot = async function(ballot) {
    try {
        const response = await axiosInstance.bhAPIInstance.post(POSTOptOutBallot, ballot);
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const parseList = response => {
    if (response.status !== 200) throw Error(response.message);
    if (!response.data) return [];
    let list = response.data;
    if (typeof list !== "object") {
        list = [];
    }
    return list;
};

const searchTodaysBallot = async function() {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETSearchTodaysBallot);
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const searchRequestedBallot = async function(id) {
    try {
        const response = await axiosInstance.bhAPIInstance.get(GETSearchRequestedBallot + id);
        return response.data;
    } catch (error) {
        return parseError(error);
    }
};

const parseError = error => {
    if (error.response.data.Errors) {
        let stringMessage = error.response.data.Errors.join(" <br/> ");
        error.response.data.Message += "<br/>" + stringMessage;
        return error.response.data;
    } else {
        return error.response.data;
    }
};


export const ballotData = {
    SearchBallot,
    SearchOpenBallot,
    searchCurrentBallot,
    SearchBallotByKey,
    SaveBallot,
    optInBallot,
    optOutBallot,
    searchBallotOptIns,
    searchAllBallotOptIns,
    removeBallot,
    toggleBallotDay,
    OptInOrOptOut,
    ballotRequestExportToExcel,
    SearchBallotByRequestKey,
    searchTodaysBallot,
    searchRequestedBallot
};